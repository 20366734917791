import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import strings from "../localization";
import CsvFileUpload from "../Components/CsvFileUpload";


const Home = () => {
  const authUser = useSelector((state) => state.AuthReducer.authUser);
  const navigate = useNavigate(); // useNavigate hook to redirect

  useEffect(() => {
    if (!authUser?.id) {
      // If user is not authenticated, redirect to /login
      navigate('/login');
    }
  }, [authUser, navigate]);

  return (
    <div className="main-container">
      { authUser?.id &&
        <p>
	    Please upload your application list in CSV format.
        </p>
      }
      <CsvFileUpload />
    </div>
  );
};

export default Home;
