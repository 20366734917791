import React, { useState } from 'react';
import axios from 'axios';
import ReactDOM from 'react-dom';
//import './globalStyles.css'; // Import global styles
//import './CsvFileUpload.css'; // Component-specific styles

const API_URL = `${process.env.REACT_APP_API_URL}/upload`;

function CsvFileUpload() {
    const [file, setFile] = useState(null);
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];

        if (selectedFile && selectedFile.type !== 'text/csv') {
            setMessage('Only CSV files are allowed.');
            return;
        }

        if (selectedFile && selectedFile.size > 10 * 1024 * 1024) { // 10 MB limit
            setMessage('File size exceeds the 10 MB limit.');
            return;
        }

        setFile(selectedFile);
        setMessage('');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!file) {
            setMessage('Please select a file first!');
            return;
        }

        setLoading(true);

        const formData = new FormData();
        formData.append('file', file);
        formData.append('listname', 'stipe_test');
        formData.append('username', 'stipe');

        try {
            const response = await axios.post(API_URL, formData, {
                headers: {
                    'x-api-key': 'd65ddbc3-e012-40a5-b710-16a39b0afbf4',
                    'x-entity-id': 'stipe_api_testing',
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response.status === 200) {
                const responseData = response.data;
                if (responseData.redirect_url) {

alert(responseData.redirect_url);
                    window.location.href = responseData.redirect_url;
                } else {
                    console.log('Upload success, but no redirect URL provided.');
                }
                setMessage('File uploaded successfully!');
            }

        } catch (error) {
            setMessage('File upload failed. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className='file-upload-component'>
            <form onSubmit={handleSubmit}>
                <div className="file-input-wrapper">
                    <label htmlFor="file-upload" className="custom-file-upload">
                        Choose File
                    </label>
                    <input id="file-upload" type="file" onChange={handleFileChange} accept=".csv" />
                </div>
                {/* Display selected file info */}
                {file && (
                    <div className="file-info">
                        <p>Selected File: <strong>{file.name}</strong></p>
                        <p>File Size: <strong>{(file.size / 1024).toFixed(2)} KB</strong></p>
                    </div>
                )}
                <div>
                    <button type="submit" className="btn" disabled={!file}>
                        {loading ? 'Uploading...' : 'Upload File'}
                    </button>
                </div>
            </form>
            {message && <p className="message">{message}</p>}
            {/* Loading overlay */}
            {loading && ReactDOM.createPortal(
                <div className="csv_overlay">
                    <div className="csv_modal">
                        <div className="csv_spinner"></div>
                        <p>Your data is being processed. Please be patient until you are redirected to the results page.</p>
                    </div>
                </div>,
                document.getElementById('root')
            )}
        </div>
    );
}

export default CsvFileUpload;
