import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import ScoreNestedTable from '../../Components/ScoreNestedTable';
import { MDBPagination, MDBPageItem, MDBPageNav } from 'mdbreact';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Button, Box, Typography } from '@mui/material';
//import { BrowserRouter as Router, Route, Routes, useParams } from 'react-router-dom';

// Dummy API URL, replace with your actual API endpoint

//const API_URL = 'https://192.168.2.100:8085/gen_score';
//const API_URL = 'https://167.99.221.74:8085/gen_score';
const API_URL = process.env.REACT_APP_GO_API_URL;

const PaginationControls = ({ currentPage, totalPages, onNextPage, onPreviousPage }) => {
  return (
    <MDBPagination mb={0}>
      <MDBPageItem disabled={currentPage <= 1}>
        <MDBPageNav aria-label="Previous" onClick={onPreviousPage}>
          <span aria-hidden="true">Previous</span>
        </MDBPageNav>
      </MDBPageItem>
      <MDBPageItem active>
        <MDBPageNav>
          {currentPage} / {totalPages}
        </MDBPageNav>
      </MDBPageItem>
      <MDBPageItem disabled={currentPage >= totalPages}>
        <MDBPageNav aria-label="Next" onClick={onNextPage}>
          <span aria-hidden="true">Next</span>
        </MDBPageNav>
      </MDBPageItem>
    </MDBPagination>
  );
};


const ScorePage = () => {
  const [data, setData] = useState({});
  const [filterDate, setFilterDate] = useState('');
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(100); // Adjust the page size as needed
  const [totalPages, setTotalPages] = useState(0);
  const [transformedData, setTransformedData] = useState({});
  const [sortField, setSortField] = useState('Sum Block Score');
  const [sortDirection, setSortDirection] = useState('desc');

  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
	const fullApiUrl = `${API_URL}/${id}`;
        const response = await axios.post(fullApiUrl, data, {
          headers: {
            // Basic Authentication header
            'Authorization': `Basic ${btoa('score:123456')}`,
            'Content-Type': 'application/json'
          },
          responseType: 'arraybuffer'
        });

        // Create a new Uint8Array from the response data
        const byteArray = new Uint8Array(response.data);

        // Create a new Blob from the Uint8Array
        const blob = new Blob([byteArray], { type: 'application/json' });

        // Use FileReader to read the Blob as text
        const reader = new FileReader();
        reader.onload = () => {
          // Parse the decompressed JSON data
          const jsonData = JSON.parse(reader.result);
          setData(jsonData);
        };
        reader.readAsText(blob);

//        setData(response.data);
	
      } catch (error) {
        console.error('Error fetching data:', error);
        // Handle error appropriately in your application
      }
    };

    fetchData();
  }, []);

  const requestSort = (field) => {
    const direction = field === sortField && sortDirection === 'asc' ? 'desc' : 'asc';
    setSortField(field);
    setSortDirection(direction);
  };

  useEffect(() => {
    if (data && Object.keys(data).length > 0) {
      const newData = transformData(data, sortField, sortDirection);
      setTransformedData(newData);
      setTotalRecords(Object.keys(newData).length);
    }
  }, [data, sortField, sortDirection]);

  useEffect(() => {
    setTotalPages(Math.ceil(totalRecords / pageSize)); // Ensure totalPages is calculated after totalRecords is updated
  }, [totalRecords, pageSize]);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  // Function to download Excel file
  const handleDownload = async () => {
    try {
      const fullApiUrlx = `${API_URL}x/${id}`;
      const response = await axios.post(fullApiUrlx, data, {
      headers: {
          'Authorization': `Basic ${btoa('score:123456')}`,
//          'Content-Type': 'application/json'
        },
        responseType: 'blob',
	timeout: 300000
//        responseType: 'arraybuffer'
      });

      // Create a download link
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'score_data.xlsx'); // Set the file name
      document.body.appendChild(link);
      link.click();
      link.remove(); // Clean up the DOM
    } catch (error) {
      console.error('Error downloading the file:', error);
    }
  };

  const currentData = Object.keys(transformedData)
    .slice((currentPage - 1) * pageSize, currentPage * pageSize)
    .reduce((obj, key) => {
      obj[key] = transformedData[key];
      return obj;
    }, {});



  return (
    <div>
      <Box display="flex" alignItems="center" justifyContent="space-between" mb={1} mt={1}>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="flex-start"
          mr={2}
	  pt={1}
          bgcolor="white"  // White background
          border="1px solid lightgray"  // Light gray border
          borderRadius={2}  // Rounded corners
        >
        <PaginationControls
          currentPage={currentPage}
          totalPages={totalPages}
          onNextPage={handleNextPage}
          onPreviousPage={handlePreviousPage}
        mb={0} />

	</Box>
{/* Grouped legends - vertically aligned */}
      <Box display="flex" flexDirection="row" alignItems="top" mx={1}>

        {/* First box - Statuses legend */}
        <Box display="flex" flexDirection="row" alignItems="flex-start">

        <Box
          display="flex"
          flexDirection="row"
          alignItems="flex-start"
          mr={2}
          p={2}  // Padding inside the box
          bgcolor="white"  // White background
          border="1px solid lightgray"  // Light gray border
          borderRadius={2}  // Rounded corners
        >

          <Box display="flex" alignItems="center" mr={2}>
            <Box width={12} height={12} bgcolor="blue" mr={1} />
            <Typography variant="body2">Active application</Typography>
          </Box>
          <Box display="flex" alignItems="center" mr={2}>
            <Box width={12} height={12} bgcolor="green" mr={1} />
            <Typography variant="body2">Active patent</Typography>
          </Box>
          <Box display="flex" alignItems="center" mr={2}>
            <Box width={12} height={12} bgcolor="orange" mr={1} />
            <Typography variant="body2">Abandoned application</Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <Box width={12} height={12} bgcolor="gray" mr={1} />
            <Typography variant="body2">Expired patent</Typography>
          </Box>
        </Box>
        </Box>

        {/* Second box - Row names legend */}
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
	  mr={2}
          p={2}  // Padding inside the box
          bgcolor="white"  // White background
          border="1px solid lightgray"  // Light gray border
          borderRadius={2}  // Rounded corners
        >
        <Box display="flex" flexDirection="row" alignItems="flex-start">
          <Typography variant="body2" mr={2}>
            <strong>MS</strong> - Mention Score
          </Typography>
          <Typography variant="body2" mr={2}>
            <strong>BS</strong> - Block Score
          </Typography>
          <Typography variant="body2">
            <strong>SBS</strong> - Sum Block Score
          </Typography>
        </Box>
        </Box>
      </Box>


        <Button variant="contained" color="primary" onClick={handleDownload}>
          Download
        </Button>
     </Box>
     <ScoreNestedTable data={currentData} onSort={requestSort}  />
    </div>
  );


};


export default ScorePage;


function transformData(originalData, sortField, sortDirection) {
    let transformed = {};

    // Iterate over each cited application (Level 1)
    Object.entries(originalData).forEach(([citedAppNo, citingAppsObj]) => {
        let citingAppsArray = [];

        // Iterate over each citing application within a cited application (Level 2)
        Object.entries(citingAppsObj).forEach(([citingAppNo, mentions]) => {
            let mentionsArray = mentions.map(mention => {
                // Level 3: Mention details
                return {
                    "mail_date": mention["mail_date"],
                    "mention ident": mention["mention ident"],
                    "code": mention["code"],
                    "error code": mention["error code"],
                    "Mention Score": mention["Mention Score"],
                    "oaid": mention["oaid"],
                };
            });

            citingAppsArray.push({
                // Level 2: Citing application details plus nested mentions
                "Citing App No": citingAppNo,
                "Citing Pub No": mentions[0]["Citing Pub No"],
                "Citing Grant No": mentions[0]["Citing Grant No"],
                "A title": mentions[0]["A title"],
                "A art unit": mentions[0]["A art unit"],
                "A assignee|applicant": mentions[0]["A assignee|applicant"],
                "A uspc": mentions[0]["A uspc"],
                "A app sts": mentions[0]["A app sts"],
                "A sts date": mentions[0]["A sts date"],
                "A filing date": mentions[0]["A filing date"],
                "A priority date": mentions[0]["A priority date"],
                "A grant date": mentions[0]["A grant date"],
                "A pub date": mentions[0]["A pub date"],
                "A inventor|applicant": mentions[0]["A inventor|applicant"],
                "A app sts ID": mentions[0]["A app sts ID"],
                "Block Score": mentions[0]["Block Score"],
                mentions: mentionsArray, // Embedding mentions (Level 3) into the citing application
            });

            // Construct the full structure for the cited application including all citing applications and their mentions
            transformed[citedAppNo] = {
                "Cited App No": citedAppNo,
                "Cited Pub No": mentions[0]["Cited Pub No"], // Example for pulling one of the mention's Cited details
                "Cited Grant No": mentions[0]["Cited Grant No"],
                "B title": mentions[0]["B title"],
                "B art unit": mentions[0]["B art unit"],
                "B assignee|applicant": mentions[0]["B assignee|applicant"],
                "B uspc": mentions[0]["B uspc"],
                "B app sts": mentions[0]["B app sts"],
                "B sts date": mentions[0]["B sts date"],
                "B filing date": mentions[0]["B filing date"],
                "B priority date": mentions[0]["B priority date"],
                "B grant date": mentions[0]["B grant date"],
                "B pub date": mentions[0]["B pub date"],
                "B inventor|applicant": mentions[0]["B inventor|applicant"],
                "B app sts ID": mentions[0]["B app sts ID"],
                "Sum Block Score": mentions[0]["Sum Block Score"],
                citingApps: [], // Including all citing applications (Level 2) and their mentions (Level 3)
             };

        });

        // Construct the full structure for the cited application including all citing applications and their mentions
        transformed[citedAppNo].citingApps = citingAppsArray; // Including all citing applications (Level 2) and their mentions (Level 3)

    });

    transformed = sortData(Object.values(transformed), sortField, sortDirection);

    return transformed;
}

function sortData(data, field, direction) {
    const multiplier = direction === 'asc' ? 1 : -1;
    return data.sort((a, b) => {
        const first = a[field] || 0;
        const second = b[field] || 0;
        return (first > second ? 1 : first < second ? -1 : 0) * multiplier;
    });
}

